<template>
  <div class="bg-gray-200 min-h-screen app_page">
    <div>
      <div
      class="app_top_image"
      v-bind:style="{ backgroundImage: `url(${background})` }"
    ></div>

    </div>

    <div class="container mx-auto main-content">
      <div class="px-3 sm:px-4 py-2 sm:py-4">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import api, {http, fileDownload} from "@/services/api";
export default {
  name: "Participate",

  data() {
    return {
      background: null,
    };
  },

  methods: {
    async getBanner() {
      const id = this.$router.history.current.params.id;
      this.background = await this.getRegistrationBanner(id);
    },

    async getRegistrationBanner(id) {
      const result = await http.request( {
        method: "get",
        url: "images/background/courseevent/" + id,
      });
      return result.data;
    },

    imageExists(url) {
      let img = new Image();
      img.src = url;
      return img.height !== 0;
    },
  },

  async mounted() {
    await this.getBanner();
  },
};
</script>

<style>
.main-content {
  margin-top: -20px;
  background-color: transparent !important;
}
.app_page {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.app_top_image {
  display: flex;
  height: 15em;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 50%;
  justify-content: flex-start;
}
</style>
<script setup></script>
